<ErrorPage {asr} {key}>
	We couldn't load any rows from this sheet!  Make sure it has rows, and there aren't any blank rows.
</ErrorPage>

<script>
	import ErrorPage from 'lib/component/ErrorPage.html'

	export default {
		components: {
			ErrorPage
		}
	}
</script>
