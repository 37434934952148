<ErrorPage {asr} {key}>
	We couldn't load that workbook!
</ErrorPage>

<script>
	import ErrorPage from 'lib/component/ErrorPage.html'

	export default {
		components: {
			ErrorPage
		}
	}
</script>
